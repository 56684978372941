@tailwind base;
@tailwind components;
@tailwind utilities;


@font-face {
  font-family: 'Jost';
  src: url('./assets/Jost-Regular.ttf') format('truetype');
  /* Agrega más fuentes y variantes según sea necesario */
}
